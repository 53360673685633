import React, { useEffect, useState } from 'react';
import './App.css';

const App = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://parking.endres.io/api/parking")
      .then(response => response.json())
      .then(result => setData(result))
      .catch(err => setError(err.message));
  }, []);

  if (error) {
    return (
      <div className="app-container">
        <p className="error">Error fetching data: {error}</p>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="app-container">
        <p className="loading">Loading...</p>
      </div>
    );
  }

  const garageNames = {
    "1": "Overture Center Garage",
    "2": "State Street Capital Garage",
    "5": "State Street Campus Garage",
    "6": "Capitol Square North Garage",
    "18": "South Livingston Street Garage",
    "19": "Wilson Street Garage"
  };

  // Function to determine text color based on vacancy
  const getTextColor = (spots) => {
    if (spots < 25) return 'red';
    if (spots < 75) return '#FFA500';
    return 'green';
  };

  // Function to determine font weight based on vacancy
  const getFontWeight = (spots) => (spots < 75 ? 'bold' : 'normal');

  return (
    <div className="app-container">
      <div className="header">
        <h1>Parking Garage Availability</h1>
        <p>Last updated: {data.modified}</p>
      </div>
      <div className="data-container">
        {Object.keys(data.vacancies).map(key => (
          <div key={key} className="garage-item">
            <span className="garage-name">{garageNames[key] || `Garage ${key}`}</span>
            <span
              className="garage-vacancy"
              style={{
                color: getTextColor(data.vacancies[key]),
                fontWeight: getFontWeight(data.vacancies[key])
              }}
            >
              {data.vacancies[key]} spots available
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
